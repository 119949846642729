import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import styled from "styled-components"

const TitleDiv = styled.div`
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
`

const DateDiv = styled.div`
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
`

const BlogGridItems = ({ grid = true, items = [] }) => {  

  return (
    <>
      {grid ? (
        <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-x-12 gap-y-12 py-12 relative justify-center justify-items-center">
          {items.map((item, index) => {
            const image = item.image ? getImage(item.image) : item.logo_image
            const logo = getImage(item.logo_image)
            return (
              <div
                className="w-full h-56 md:h-72 relative flex blog-page-item"
                style={{ animationDelay: `${0.5 + index * 0.2}s` }}
                key={`portfolio-${index}-${item.uid}`}
              >
                {image ? (
                  <GatsbyImage
                    image={image}
                    alt={item.image_alt}
                    className="w-full h-full object-cover z-20"
                    style={{ borderRadius: "5px" }}
                  />
                ) : (
                  <GatsbyImage
                    image={logo}
                    alt={item.image_alt}
                    objectFit="contain"
                    className="w-full h-full object-cover z-20 bg-site-gray"
                    style={{ borderRadius: "5px" }}
                  />
                )}
                <div className="absolute w-full h-full z-10 bg-white">
                  &nbsp;
                </div>
                <div className="absolute w-full h-full flex justify-center items-end">
                  <div
                    className="w-full"
                    style={{
                      borderRadius: "100%",
                      width: "80%",
                      height: "100px",
                      boxShadow: "0px 5px 24px 10px black",
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
                <a
                  href={`/blog-details/${item.uid}`}
                  className="portfolio-page-gridItem"
                >
                  <TitleDiv className="text-center w-full px-2 pt-4 uppercase">
                    {item.title || <>&nbsp;</>}
                  </TitleDiv>
                  {/* {item.date && (
                    <DateDiv className="text-center w-full px-2 pb-4 text-gray-300 text-sm">
                      {item.date?.toLocaleDateString()}
                    </DateDiv>
                  )} */}
                </a>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-12 md:gap-y-12 py-12 relative justify-center justify-items-center">
          {items.map((item, index) => {
            const image = getImage(item.image)
            const logo = getImage(item.logo_image)
            return (
              <div
                className="w-full relative flex flex-col md:flex-row blog-page-item"
                style={{ animationDelay: `${0.5 + index * 0.2}s` }}
                key={`portfolio-${index}-${item.uid}`}
              >
                <div
                  className="h-full w-full md:w-2/5 flex justify-center items-center "
                  style={{
                    minWidth: "40%",
                  }}
                >
                  <a href={`/blog-details/${item.uid}`}>
                    {image ? (
                      <GatsbyImage
                        image={image}
                        alt={item.image_alt}
                        className="w-60 md:w-56 h-36 md:h-32 object-cover z-20 border hover:border-site-yellow"
                        style={{ borderRadius: "5px" }}
                      />
                    ) : (
                      <GatsbyImage
                        image={logo}
                        alt={item.image_alt}
                        objectFit="contain"
                        className="w-60 md:w-56 h-36 md:h-32 object-cover z-20 border hover:border-site-yellow bg-site-gray"
                        style={{ borderRadius: "5px" }}
                      />
                    )}
                  </a>
                </div>
                <div className="flex w-full md:w-3/5 flex-col justify-center px-4 pt-4">
                  <a href={`/blog-details/${item.uid}`}>
                    <span className="font-semibold blog-text-title hover:text-site-yellow">
                      {item.title || <>&nbsp;</>}
                    </span>
                  </a>
                  {/* {item.date && (
                    <span className="text-gray-500 text-xs">
                      {item.date?.toLocaleDateString() || <>&nbsp;</>}
                    </span>
                  )} */}
                  {item.summary?.trim() && (
                    <div className="pt-2 text-sm blog-text-ellipsis">
                      {item.summary}
                    </div>
                  )}
                  <a
                    href={`/blog-details/${item.uid}`}
                    className="text-sm text-site-yellow pt-4 hover:underline"
                  >
                    See more...
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default BlogGridItems
