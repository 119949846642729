import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import { graphql, useStaticQuery } from "gatsby"
import "./blog-page-styles.css"
import PageSelection from "../../components/common/PageSelection"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import useWindowSize from "../../utils/useWindowSize"
import BlogGridItems from "../../components/common/BlogGridItems"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import HeroSectionWithTitle from "../../components/common/HeroSectionWithTitle"
import _ from "lodash"

const PortfolioPage = () => {
  const screen = useWindowSize()
  const [gridView, setGridView] = useState(false)
  const [selectedBlogs, setSelectedBlogs] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [searchKey, setSearchKey] = useState("")
  const prismicBlogs = useStaticQuery(graphql`
    query BlogssPageQuery {
      allPrismicBasePage(filter: { uid: { eq: "blog" } }) {
        edges {
          node {
            data {
              title
              hero_image {
                fluid {
                  src
                }
                gatsbyImageData(width: 1000, placeholder: BLURRED)
              }
            }
          }
        }
      }
      allPrismicBlog {
        nodes {
          uid
          tags
          data {
            title
            summary
            published_date
            preview_image {
              alt
              gatsbyImageData(width: 400)
            }
          }
          last_publication_date
        }
        distinct(field: tags)
      }
      prismicBlogOrder {
        data {
          ordering {
            blog_article {
              uid
            }
          }
        }
      }
      imageSharp(fluid: { originalName: { eq: "bja-logo.png" } }) {
        gatsbyImageData
      }
    }
  `)

  const origBlogs = useMemo(() => {
    if (prismicBlogs) {
      const data = prismicBlogs.allPrismicBlog.nodes.map(node => {
        const { uid, data, tags, first_publication_date } = node
        const { title, published_date, preview_image, summary } = data

        return {
          uid,
          title,
          summary,
          date: published_date ? new Date(published_date) : null,
          image: preview_image,
          logo_image: prismicBlogs.imageSharp,
          image_alt: preview_image.alt ?? title,
          first_publication_date: first_publication_date,
          tags,
        }
      })

      const sortKey = prismicBlogs.prismicBlogOrder.data.ordering.map(order => {
        return order.blog_article.uid
      })
      let counter = 0;
      const sorted = _.sortBy(data, blog => {
        const index = _.indexOf(sortKey, blog.uid)
        counter++;
        return  index >= 0 ? index : (99999 - counter)
      })

      return sorted
    }

    return []
  }, [prismicBlogs])

  const categories = useMemo(() => {
    const data = [
      {
        value: "all",
        label: "All",
      },
    ]
    if (prismicBlogs) {
      for (const tag of prismicBlogs.allPrismicBlog.distinct) {
        data.push({
          value: tag.toLowerCase(),
          label: tag,
        })
      }
    }
    setSelectedCategory(data[0].label)
    return data
  }, [prismicBlogs])

  useEffect(() => {
    setSelectedBlogs(origBlogs)
  }, [])

  useEffect(() => {
    let filtered = [...origBlogs]
    if (selectedCategory) {
      if (selectedCategory !== "All") {
        filtered = selectedBlogs.filter(p => p.tags.includes(selectedCategory))
      }
    }

    if (searchKey) {
      filtered = filtered.filter(blog => {
        return (
          blog.summary.toLowerCase().includes(searchKey.toLowerCase()) ||
          blog.title.toLowerCase().includes(searchKey.toLowerCase())
        )
      })
    }

    setSelectedBlogs(filtered)
  }, [selectedCategory, searchKey])

  const heroImage = getImage(
    prismicBlogs.allPrismicBasePage.edges[0].node.data.hero_image
  )
  return (
    <Layout>
      <SEOnew
        title={prismicBlogs.allPrismicBasePage?.edges[0]?.node.data.title}
      />
      <div className="w-full relative flex flex-col items-center justify-center">
        <HeroSectionWithTitle
          title={prismicBlogs.allPrismicBasePage?.edges[0]?.node.data.title}
          description={
            "Brion Jeannette Architecture prepares blogs to provide helpful information to those building or remodeling their home"
          }
          textLocation="left"
          image={heroImage}
        />
      </div>
      <div className="flex w-full flex-col pt-2 mb-12 min-h-screen items-center">
        {/* <div className="w-full max-w-7xl flex flex-col items-center justify-center">
          <div
            className="bg-gray-100 w-full max-w-5xl py-6 shadow-lg"
            style={{ borderRadius: "8px" }}
          >
            <PageSelection
              categories={categories}
              label={`Style`}
              onCategorySelect={setSelectedCategory}
              gridView={gridView}
              onViewChange={() => setGridView(!gridView)}
              onSearch={setSearchKey}
              enableCategorySelect={false}
            />
          </div>
        </div> */}
        <div className="w-full max-w-7xl px-4">
          <BlogGridItems grid={gridView} items={selectedBlogs} />
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default PortfolioPage
